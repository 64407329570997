import { Text } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";

import { type NfcTagRequest } from "../../../Shift/types";
import { type NfcHashValidationAction } from "../../api/types";
import { ScanInstructionDetails } from "../ScanInstructionDetails";
import { clockActionToReadableString } from "../utils";

interface ScanInstructionsStageProps {
  facilityName: string;
  nfcTagRequests: NfcTagRequest[];
  clockAction: NfcHashValidationAction;
  onStart: () => void;
  onBack: () => void;
}

export function ScanInstructionsStage({
  facilityName,
  nfcTagRequests,
  clockAction,
  onStart,
  onBack,
}: ScanInstructionsStageProps) {
  return (
    <Stack minHeight="100%">
      <Stack flexGrow="1" spacing={3}>
        <Text bold variant="h2">
          Use a Clipboard NFC Time Clock to {clockActionToReadableString(clockAction)}
        </Text>
        <ScanInstructionDetails facilityName={facilityName} nfcTagRequests={nfcTagRequests} />
      </Stack>

      <Stack spacing={2} mt={2}>
        <Button variant="contained" onClick={onStart}>
          Begin NFC scan
        </Button>
        <Button variant="outlined" onClick={onBack}>
          Go back
        </Button>
      </Stack>
    </Stack>
  );
}
